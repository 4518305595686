.navbar {
  background-color: #f6f6f6;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  height: 80px;
  display: flex;
  justify-content: right;
  align-items: center;
  font-size: 20px;
  position: fixed;
  top: 0;
  z-index: 100;
  padding-right: 30px;
  width: 100vw;
}

.navbar-logo {
  color: #1a3a5c;
  margin-left: 10px;
  top: 0;
  text-align: start;
  left: 0;
  cursor: pointer;
  text-decoration: none;
  font-size: 25px;
}
.nav-links-mobile {
  display: flex;
  display: block;
  text-align: center;
  border-radius: 20px;
  width: wrap;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 20px;
  background: #eba68a;
  text-decoration: none;
  color: white;
}
.nav-links-mobile:hover {
  background: #1a3a5c;
  color: white;
  text-decoration: none;
  transition: 250ms;
}
.nav-links:hover {
  border-bottom: 4px solid #1a3a5c;
  text-decoration: none;
  transition: all 0.2s ease-out;
}
.nav-m {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  margin-right: 20px;
  justify-content: end;
}
.nav-item {
  height: 80px;
}
.nav-links {
  color: #1a3a5c;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

@media screen and (max-width: 930px) {
  .nav-m {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    text-decoration: none;
    opacity: 1;
  }
  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .navbar-logo {
    position: absolute;
    margin-left: 20px;
    top: 0;
    line-height: 80px;
    text-align: start;
    align-self: flex-start;
  }
}

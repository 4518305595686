.programwrap {
  height: 100%;
  width: 100%;
  background-color: #f6f6f6;
  margin-top: 80px;
  padding-bottom: 200px;
}
.program_nav {
  display: flex;
  flex: 1 1 0px;
  height: 75px;
  flex-shrink: 0;
  flex-direction: row;
  background-color: #ddeced;
  justify-content: space-evenly;
  align-items: center;
}
.program_details_nav {
  display: flex;
  flex: 1 1 0px;
  height: 50px;
  flex-shrink: 0;
  flex-direction: row;
  background-color: #fbfbfb;
  justify-content: space-evenly;
  align-items: center;
}
.programline {
  height: 75px;
  width: 1px;
  background-color: #89a8c8;
  opacity: 0.5;
  margin: 0;
  padding: 0;
}
.details_line {
  height: 50px;
  width: 1px;
  background-color: lightgray;
  opacity: 0.5;
  margin: 0;
  padding: 0;
}
.program_nav_wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75px;
  padding: 20px;
  flex-basis: 100%;
  text-align: center;
  cursor: pointer;
  color: #1a3a5c;
  font-size: 20px;
  flex-grow: 1;
}
.program_details_nav_wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 20px;
  flex-basis: 100%;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  color: #1a3a5c;
  border: 1px solid lightgray;
  flex-grow: 1;
}
.program_details_nav_wrap_active {
  background-color: white;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 20px;
  flex-basis: 100%;
  text-align: center;
  cursor: pointer;
  color: #1a3a5c;
  border: 1px solid transparent;
  border-top: 1px solid lightgray;

  flex-grow: 1;
}

.program_details_nav_wrap:hover {
  background-color: white;
  transition: 250ms;
}
.program_nav_wrap:hover {
  background-color: #cee2e4;
  transition: 250ms;
}
.program_nav_wrap_active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75px;
  padding: 20px;
  flex-basis: 100%;
  text-align: center;
  cursor: pointer;
  color: #1a3a5c;
  font-size: 20px;
  flex-grow: 1;
  background-color: #cee2e4;
}
.programwrap_nav {
  flex-basis: 100%;
  display: flex;
  flex-direction: row;
}
.program_content {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
}
.program_others {
  width: 30%;
  height: wrap;
  margin-top: 50px;
  background-color: #f6f6f6;
  padding: 50px;
}
.program_details {
  width: 70%;
  height: wrap;
  background-color: white;
}
.programs_title {
  color: #1a3a5c;
  font-weight: 700;
  font-size: 22px;
  padding: 20px;
  font-family: 'Playfair Display';
  line-height: 25px;
  padding-left: 0;
}
.programs_others_content {
  color: #1a3a5c;
  font-weight: 400;
  font-size: 17px;
  padding: 20px;
  line-height: 30px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 0;
}
.programs_wrap {
  margin-top: 20px;
  margin-bottom: 40px;
}
.program_others_line {
  height: 1px;
  background-color: lightgray;
  opacity: 0.5;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0;
}
.program_details_contents_title {
  font-family: 'Playfair Display';
  color: #77c1c6;
  font-weight: 700;
  font-size: 50px;
  letter-spacing: 3px;
  padding-bottom: 70px;
  line-height: 30px;
}
.schedule_image {
  width: 80%;
}
.program_details_contents {
  padding: 70px;
}

.linklanding {
  text-decoration: none;
}
.program_details_contents_catchline {
  color: #1a3a5c;
  padding-bottom: 15px;
  font-family: 'Playfair Display';
  font-size: 30px;
  line-height: 40px;
}
.program_details_contents_catchline_wrap {
  padding-bottom: 40px;
}
.program_details_contents_content {
  color: #1a3a5c;
  padding-bottom: 20px;
  line-height: 40px;
  font-size: 20px;
  width: 80%;
}
.program_details_schedule_title {
  font-family: 'Playfair Display';
  color: #1a3a5c;
  padding-bottom: 20px;
  line-height: 40px;
  font-weight: 700;
  font-size: 25px;
  width: 80%;
}
.program_details_schedule_wrap {
  padding-bottom: 30px;
}
.programpage_reg {
  margin-top: 30px;
  display: flex;
  display: block;
  text-align: center;
  border-radius: 5px;
  width: 22vw;
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 18px;
  background: white;
  cursor: pointer;
  text-decoration: none;
  color: #1a3a5c;
  border: 1px solid #1a3a5c;
}
.programpage {
  margin-top: 30px;
  display: flex;
  display: block;
  text-align: center;
  border-radius: 5px;
  width: 16vw;
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 18px;
  background: #f6f6f6;
  cursor: pointer;
  text-decoration: none;
  color: #1a3a5c;
  border: 1px solid #1a3a5c;
}
.programpage_reg:hover {
  background: #1a3a5c;
  color: white;
  text-decoration: none;
  transition: 250ms;
}
.programpage:hover {
  background: #1a3a5c;
  color: white;
  text-decoration: none;
  transition: 250ms;
}

.applicationtimeline {
  width: 90%;
}
@media screen and (max-width: 960px) {
  .program_nav_wrap {
    font-size: 16px;
  }
  .program_nav_wrap_active {
    font-size: 16px;
  }
  .program_details_contents_title {
    font-size: 40px;
  }
  .program_details_contents_catchline {
    font-size: 20px;
    line-height: 35px;
  }
  .program_details_contents_content {
    font-size: 17px;
    width: 100%;
  }
  .programs_title {
    font-size: 22px;
    padding: 0;
  }
  .programs_others_content {
    font-size: 15px;
    padding: 0;
    padding-top: 20px;
  }
  .program_others {
    padding: 30px;
  }
}

@media screen and (max-width: 400px) {
  .program_content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .program_details {
    width: 90%;
  }
  .program_others {
    width: 90%;
  }
  .programs_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.applicationwrap {
  background-color: white;
  height: wrap;
  width: 100%;
  padding: 100px;
  margin-bottom: 200px;
  margin-top: 100px;
}

.applicationtitle {
  font-family: 'Playfair Display';
  color: #77c1c6;
  font-weight: 700;
  font-size: 50px;
  letter-spacing: 3px;
  padding-bottom: 30px;
  line-height: 50px;
}
.applicationsubtitle {
  color: #1a3a5c;
  padding-bottom: 30px;
  padding-top: 30px;
  font-family: 'Playfair Display';
  font-size: 30px;
  line-height: 40px;
}
.applicationtimeline {
  width: 90%;
}
.applicationcontents {
  color: #1a3a5c;
  line-height: 35px;
  margin-bottom: 50px;
  font-size: 20px;
}
.applybutton {
  margin-top: 30px;
  display: flex;
  display: block;
  text-align: center;
  border-radius: 5px;
  width: 350px;
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 25px;
  background: white;
  cursor: pointer;
  font-family: 'Playfair Display';
  font-weight: 700;
  text-decoration: none;
  word-spacing: 10px;
  color: #1a3a5c;
  border: 1px solid #1a3a5c;
}
.applybutton:hover {
  background: #1a3a5c;
  color: white;
  text-decoration: none;
  transition: 250ms;
}
.applywrap {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 400px) {
  .applicationwrap {
    padding: 10px;
  }
}

.landingwrap {
  height: 100%;
  width: 100%;
  background-color: white;
  margin-top: 150px;
}
.herosection {
  background-color: white;
  height: wrap;
  width: wrap;
}
.landingpic {
  width: 100%;
  margin-top: 150px;
  overflow: hidden;
}
.curve {
  margin: 0;
  padding: 0;
}
.summerprograms {
  background-color: white;
  width: 100%;
  height: wrap;
  width: wrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px;
}
.landingslogan {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60%;
  margin-top: -20px;
  background-color: #d5e2f0;
  padding-bottom: 60px;
}
.landingtitle {
  color: #1a3a5c;
  font-size: 50px;
  font-family: 'Playfair Display';
  font-weight: 700;
  text-align: center;
  padding: 20px;
  padding-top: 0;
  padding-bottom: 50px;
}
.landingtitle_summer {
  color: #1a3a5c;
  font-size: 50px;
  font-family: 'Playfair Display';
  font-weight: 700;
  text-align: center;
  padding: 20px;
  padding-top: 0;
  padding-bottom: 0px;
}
.landingcontent {
  color: #1a3a5c;
  padding: 200px;
  padding-bottom: 0px;
  padding-top: 0px;
  text-align: center;
  line-height: 40px;
  font-size: 25px;
}
.programs-wrap {
  margin-top: 30px;
  color: #1a3a5c;
  display: flex;
  flex-direction: row;
}
.summerprogram-wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 0px;
  padding: 30px;
}
.summerprogram-title {
  text-align: center;
  color: #1a3a5c;
  font-size: 22px;
  margin: 10px;
  margin-bottom: 50px;
}
.summerprogram-content {
  font-size: 15px;
  color: #1a3a5c;
  text-align: center;
  line-height: 27px;
}
.learnmorebutton {
  margin-top: 30px;
  display: flex;
  display: block;
  text-align: center;
  border-radius: 5px;
  width: 200px;
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 18px;
  background: white;
  cursor: pointer;
  text-decoration: none;
  color: #1a3a5c;
  border: 1px solid #1a3a5c;
}
.linklanding {
  text-decoration: none;
}
.registerbtn {
  margin-top: 70px;
  display: flex;
  display: block;
  text-align: center;
  border-radius: 5px;
  width: 370px;
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 25px;
  background: #d5e2f0;
  cursor: pointer;
  text-decoration: none;
  color: #1a3a5c;
  border: 1px solid #1a3a5c;
}
.learnmorebutton:hover {
  background: #1a3a5c;
  color: white;
  text-decoration: none;
  transition: 250ms;
}
.registerbtn:hover {
  background: #1a3a5c;
  color: white;
  text-decoration: none;
  transition: 250ms;
}
.virtualseminar {
  width: 100%;
  height: wrap;
  width: wrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px;
  background-color: #f6f6f6;
  padding-bottom: 180px;
}
.seminartime {
  color: #77c1c6;
  font-family: 'Playfair Display';

  font-weight: 900;
  padding: 30px;
  font-size: 50px;
  text-align: center;
}
.seminardate,
.seminartitle {
  padding: 10px;
  font-size: 18px;
  text-align: center;
  color: #2c2b27;
}
.joinbutton {
  margin-top: 30px;
  display: flex;
  display: block;
  text-align: center;
  border-radius: 10px;
  width: 300px;
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 20px;
  background: #f6f6f6;
  cursor: pointer;
  text-decoration: none;
  color: #1a3a5c;
  border: 1px solid #1a3a5c;
}
.joinbutton:hover {
  background: #1a3a5c;
  color: #f6f6f6;
  text-decoration: none;
  transition: 250ms;
}
@media screen and (max-width: 1330px) {
  .landingtitle {
    font-size: 35px;
  }
  .landingcontent {
    font-size: 20px;
    padding: 70px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .registerbtn {
    font-size: 20px;
  }
}
@media screen and (max-width: 930px) {
  .programs-wrap {
    flex-direction: column;
  }
  .summerprogram-wrap {
    padding-left: 80px;
    padding-right: 80px;
  }
  .learnmorebutton {
    width: 400px;
  }
  .landingtitle {
    font-size: 35px;
  }
  .landingcontent {
    font-size: 20px;
    padding: 70px;
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media screen and (max-width: 530px) {
  .summerprogram-wrap {
    padding-left: 20px;
    padding-right: 20px;
  }
  .learnmorebutton {
    width: 350px;
  }
  .landingtitle {
    padding-top: 30px;
    font-size: 35px;
  }
  .landingcontent {
    font-size: 17px;
    padding: 70px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.aboutwrap {
  height: 100%;
  width: 100%;
  background-color: #f6f6f6;
  padding-top: 100px;
}
.aboutsecond {
  background-color: white;
  width: 100%;
  padding: 0;
  padding-top: 100px;
  height: 100%;
  margin-top: -10px;
}
.curve {
  width: 100%;
}
.aboutfirst {
  padding: 90px;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.about_icons {
  width: 20%;
  margin: 30px;
}
.abouttitle {
  color: #1a3a5c;
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  padding: 20px;
  font-family: 'Playfair Display';
}
.aboutleft {
  width: 70%;
  padding: 20px;
}
.aboutustitle {
  margin: 30px;
  width: 50%;
}
.aboutright {
  width: 30%;
}
.titleslogan {
  color: #1a3a5c;
  margin: 20px;
  margin-left: 30px;
  line-height: 35px;
  width: 80%;
  font-size: 20px;
}
.visit {
  margin-top: 60px;
  display: flex;
  display: block;
  text-align: center;
  border-radius: 10px;
  width: 280px;
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 20px;
  background: #77c1c6;
  margin-left: 30px;

  cursor: pointer;
  text-decoration: none;
  color: #f6f6f6;
  border: 1px solid #77c1c6;
}
.visit:hover {
  background-color: #f6f6f6;
  color: #77c1c6;
  transition: 250ms;
}
.about_uscont {
  padding-top: 60px;
  background-color: #efe9e5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: wrap;
}
.about_ustitle {
  color: #745140;
  font-size: 50px;
}
.traitwrap {
  display: flex;
  padding: 50px;
  flex-direction: row;
  background-color: white;
  align-items: flex-start;
  justify-content: end;
  padding-bottom: 200px;
  padding-top: 10px;
}
.about_ustrait {
  padding-left: 40px;
  padding-right: 40px;
}
.about_uswrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.traitename {
  color: #1a3a5c;
  text-align: center;
  margin-bottom: 20px;
  font-size: 25px;
  float: bottom;
}
.aboutusimage {
  width: 100%;
}
.traitcontent {
  color: #1a3a5c;
  text-align: center;
  margin-top: 20px;
  font-size: 19px;
}
.about_usline {
  line-height: 5px;
  background-color: #77c1c6;
}

@media screen and (max-width: 1080px) {
  .traitename {
    font-size: 23px;
    margin-bottom: 20px;
  }
  .about_ustrait {
    padding-left: 20px;
    padding-right: 20px;
  }
  .traitcontent {
    margin-top: 20px;
    font-size: 17px;
  }
  .aboutusimage {
    display: none;
  }
  .aboutleft {
    width: 100%;
  }
  .aboutright {
    width: 0%;
  }
  .about_icons {
    width: 30%;
  }
}
@media screen and (max-width: 868px) {
  .traitwrap {
    display: flex;
    padding: 50px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: end;
  }
  .traitename {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .about_ustrait {
    padding-left: 50px;
    padding-right: 50px;
  }
  .traitcontent {
    margin-top: 20px;
    margin-bottom: 100px;
    font-size: 20px;
  }
  .aboutustitle {
    width: 70%;
    margin: 0;
    margin-left: 30px;
  }
  .aboutfirst {
    padding: 60px;
  }
  .titleslogan {
    font-size: 17px;
  }
}

@media screen and (max-width: 400px) {
  .aboutfirst {
    padding: 10px;
  }
}

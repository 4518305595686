.landingfooter {
  width: 100%;
  flex: 1 1 0px;
  height: wrap;
  width: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 80px;
  background-color: #1a3a5c;
}
.namelink {
  color: white;
  font-size: 30px;
  text-align: center;
  text-decoration: none;
}
.footerrights {
  margin-top: 15px;
  color: white;
  font-size: 15px;
  text-align: center;
  text-decoration: none;
}
.iconswrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.icons {
  color: white;
  font-size: 30px;
  padding: 20px;
}

@media screen and (max-width: 930px) {
  .landingfooter {
    flex-direction: column;
    padding: 50px;
  }
  .footername,
  .footerrights,
  .iconswrap {
    padding: 5px;
  }
}
